<template>
  <div class="">
    <van-nav-bar left-arrow :title="$t('pur.详情')" fixed @click-left="$router.go(-1)" />
    <van-collapse v-model="activeNames" accordion style="margin-top: 55px">
      <div class="splitTitle">{{ $t('pur.基本信息') }}</div>
      <van-collapse-item :title="$t('pur.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('pur.作废状态') }}</h5>
          <p>{{ this.ruleForm.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.账套') }}</h5>
          <p>{{ ruleForm.purDomesticTradeHeader.signOwner }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购合同号') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.contractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.合同类型') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.contType | setDict('PUR_CONTRACT_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售去向') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.tradeType | setDict('PUR_TRADE_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.supplierName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商等级') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.riskLevel }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.贸易地区') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.tradeTel }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.业务员') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.purSalesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.币种') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.原币金额') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.contractAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.对人民币汇率') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.exchangeRateRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.人民币金额') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.amountRmb | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.对美元汇率') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.exchangeRateUsd }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.美元金额') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.amountUsd | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否寄售业务') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.isConsignmentBusiness | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.预计交货日期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.deliveryDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.合同签署日期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.signDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.业务部门') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.purDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.溢短装率') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.overShortLoadingRate ? ruleForm.purDomesticTradeHeader.overShortLoadingRate+'%':'' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购数量') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.purQuantity | formatAmount(6,false) }}</p>
        </div>
        <div class="items" v-if="(ruleForm.purDomesticTradeHeader.orgCode==='112' || ruleForm.purDomesticTradeHeader.orgCode==='106') && ruleForm.purDomesticTradeHeader.contType !=='1'">
          <h5>{{ $t('pur.增值税率') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.vatOtherRate | setDict('VAT_OTHER_RATE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.生效日期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.effectiveDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.状态') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.备注') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.remarks }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.性质')" name="2" v-show="ruleForm.purDomesticTradeHeader.orgCode==='1' && ruleForm.purDomesticTradeHeader.contType!=='4'">
        <div class="items">
          <h5>{{ $t('pur.是否标准合同') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.isStandardContract | setDict('GROUP_APPROVAL_OR_NOT') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否定制商品') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.isCustomGoods | setDict('GROUP_APPROVAL_OR_NOT')  }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.性质')" name="3" v-show="(ruleForm.purDomesticTradeHeader.orgCode==='106' || ruleForm.purDomesticTradeHeader.orgCode==='112') && ruleForm.purDomesticTradeHeader.isStandardContract ==='Y' ">
        <div class="items">
          <h5>{{ $t('pur.包装') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.packing }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.装运标记') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.shippingMark }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.质量') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.quality }}</p>
        </div>
        <div class="items">
          <h5>Doc Required</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.docRequired }}</p>
        </div>
        <div class="items">
          <h5>Controversy &Laws</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.controversyLaws }}</p>
        </div>
        <div class="items">
          <h5>Others</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.othersNature }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.合同条款')" name="4" v-show="(ruleForm.purDomesticTradeHeader.orgCode==='106' || ruleForm.purDomesticTradeHeader.orgCode ==='112') && ruleForm.purDomesticTradeHeader.contType !=='4'">
        <div class="items">
          <h5>{{ $t('pur.价格条款') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.priceTerms | setDict('PRICE_TERMS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.运输方式') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.shippingType | setDict('TYPE_OF_SHIPPING') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否标准合同') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.isStandardContract | setDict('GROUP_APPROVAL_OR_NOT') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.装运港') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.shipmentPortName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.目的港') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.destinationPort }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.预计交货日期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.deliveryDate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.合同条款')" name="5" v-if="ruleForm.purDomesticTradeHeader.orgCode==='1'&& ruleForm.purDomesticTradeHeader.isStandardContract ==='Y' && ruleForm.purDomesticTradeHeader.contType !=='4'">
        <div class="items">
          <h5>{{ ruleForm.purDomesticTradeHeader.orgCode === '1' ? $t('pur.质量及检验') :$t('pur.质量标准') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.qualityStandard }}</p>
        </div>
        <div class="items" v-if="ruleForm.purDomesticTradeHeader.orgCode !=='1'">
          <h5>{{ $t('pur.保质期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.hedgingPeriod }}</p>
        </div>
        <div class="items" v-if="ruleForm.purDomesticTradeHeader.orgCode !=='1'">
          <h5>{{ $t('pur.唛头及标签') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.shippingMarks }}</p>
        </div>
        <div class="items" v-if="ruleForm.purDomesticTradeHeader.orgCode !=='1'">
          <h5>{{ $t('pur.开票周期天') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.billingCycle }}</p>
        </div>
        <div class="items" v-if="ruleForm.purDomesticTradeHeader.orgCode !=='1'">
          <h5>{{ $t('pur.发票到达周期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.invoiceArrivalPeriod }}</p>
        </div>
        <div class="items" v-if="ruleForm.purDomesticTradeHeader.orgCode !=='1'">
          <h5>{{ $t('pur.赔偿税率') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.compensationTaxRate ? ruleForm.purDomesticTradeHeader.compensationTaxRate+'%':'' }}</p>
        </div>
        <div class="items" v-if="ruleForm.purDomesticTradeHeader.orgCode !=='1'">
          <h5>{{ $t('pur.增值税率') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.vatRate ? ruleForm.purDomesticTradeHeader.vatRate+'%':'' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.延迟开票违约金元天') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.liquidatedDamages }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.违约责任') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.liability }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.包装要求') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.packRequirements }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.运输及交付') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.transportationDelivery }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.商品明细')" name="commodity">
        <div v-if="!ruleForm.purDomesticTradeHeader.purDomesticTradeLineList || !ruleForm.purDomesticTradeHeader.purDomesticTradeLineList.length">{{ $t('pur.无') }}</div>
        <div v-for="(item, index) in ruleForm.purDomesticTradeHeader.purDomesticTradeLineList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.商品名称') }}</h5>
            <p>{{ item.commodityDetails }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.供应商信用')" name="credit">
        <supplierCredit :supplierId="ruleForm.purDomesticTradeHeader.supplierId" :orgId="ruleForm.purDomesticTradeHeader.orgId"/>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('pur.附件') }}</div>
      <van-collapse-item :title="$t('pur.附件信息')" name="7">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('pur.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('pur.系统信息') }}</div>
      <van-collapse-item :title="$t('pur.系统信息')" name="6">
        <div class="items">
          <h5>{{ $t('pur.制单日期') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.制单人') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.修改时间') }}</h5>
          <p>{{ this.ruleForm.purDomesticTradeHeader.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';
import supplierCredit from '../supplierCredit';

export default {
  components: {
    myHistoryPanel,
    myApproval,
    supplierCredit
  },
  data() {
    return {
      attachmentList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'purDomesticTrade',
      processInstanceName: '境内采购合同作废',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      ruleForm: {
        purDomesticTradeHeader: {
          orgId: '',
          deptId: '',
          orgCode: '',
          tradeType: '',
          signDate: '',
          purQuantity: '',
          deliveryDate: '',
          exchangeRateUsd: '',
          amountUsd: '',
          packing: 'SATNDARD SEA WORTHY PACKING',
          shippingMark: 'AS PER ATTACHMENT',
          quality: 'GRADE',
          docRequired: 'IF EXECUTTON OF THE CONTRACT DISPUTE,BOTH PARTIES SHOULD FIRSTLY CONSULTANTLY RESOL VE IT,IF FAILED,EITHER PARTY HAS RIGHTS TO PROSECUTE TO THE COUER IN BEIJING',
          controversyLaws: 'IF EXECUTTON OF THE CONTRACT DISPUTE,BOTH PARTIES SHOULD FIRSTLY CONSULTANTLY RESOL VE IT,IF FAILED,EITHER PARTY HAS RIGHTS TO PROSECUTE TO THE COUER IN BEIJING',
          othersNature: 'THE SUPPLIER SHOULD NOT PRINT OR PUT ANY FACTORY INFORMATTON ON THE OUTSUDE AND INSIDE  CARTONS,INCLUDING FACTORY NAME,THELEPHONE,FAX,ADDRESS,CONTACT OERSON,EMAIL,WEBSITE,AND CATALOG ETC.OTHERWISE,30%OF THIS CONTRACT AS A PENALTY',
          shipmentPortName: '', // 装运港名称
          shipmentPortId: '',
          destinationPort: '', // 目的港名称
          destinationPortId: '',
          priceTerms: '', // 价格条款
          shippingType: '', // 运输方式
          qualityStandard: '', // 质量标准
          hedgingPeriod: '', // 保质期
          packRequirements: '', // 包装要求
          shippingMarks: '',
          billingCycle: '7',
          invoiceArrivalPeriod: '',
          compensationTaxRate: '13',
          vatRate: '13',
          liquidatedDamages: undefined,
          transportationDelivery: '',

          tradeTel: '',
          isRelationTransaction: 'N',
          isStandardContract: 'Y',
          isCustomGoods: 'N',
          riskLevel: '',
          isFundAdvance: 'N',
          isMajorContract: 'N',
          isPayCommission: 'N',
          commissionAmount: '',
          isIronBusiness: 'N',
          isRiskBusiness: 'N',
          deliveryType: '',
          tradeMode: '',
          purDomesticTradeLineList: [],
          // purDomesticTradeProfitList: [],
          purDomesticTradeProfit: {},
          purDomesticTradeErpList: [],
          //  purDomesticTradeBusinessList: [],
          purDomesticOpportunityList: [],
          purDomesticContractList: [],
          storedList: [],
          paidList: [],
          ticketsReceivedList: [],
          finExpenseRegList: [],
          storedAmount: '',
          noStoredAmount: '',
          paidAmount: '',
          noPaidAmount: '',
          ticketsReceivedAmount: '',
          noTicketsReceivedAmount: '',
          isUse: 'N',
          version: 0,
          id: '',
          signOwnerId: '',
          purCategory: '1',
          signOwner: '',
          applicationDate: '',
          contractNo: '',
          status: 'PREPARING',
          endStatus: '',
          purSalesMan: '',
          purSalesManId: '',
          salesMan: '',
          salesManId: '',
          purDeptCode: '',
          purDeptName: '',
          purDeptId: '',

          effectiveDate: '',
          supplierId: '',
          supplierName: '',
          actualSupplierId: '',
          actualSupplierName: '',
          expandSharing: '',
          businessDescription: '',
          contractAmount: 0,
          contractingPlace: '',
          overShortLoadingRate: '',
          salesNature: '',
          businessManagementCardNumber: '',
          inventoryMethod: '',
          professionalTemplate: '',
          isGroupApproval: '',
          isNewCustomer: '',
          settlementFormula: '',
          customerName: '',
          customerId: '',
          deliveryMethod: '',
          domesticMarginAmount: '',
          domesticMarginRatio: 0,
          priceIncrease: 0,
          originalContractNo: '',
          isOldVersion: 'N'
        }
      },
    };
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.purDomesticTradeHeader.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/purDomesticTradeHeaderAbolish/view',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        debugger
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.purDomesticTradeHeader.contractNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('pur.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/purDomesticTradeHeaderAbolish/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('pur.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/purDomesticTradeHeaderAbolish/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/pur/purDomesticTradeHeaderAbolish/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('pur.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/purDomesticTradeHeaderAbolish/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
